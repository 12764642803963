import { ProjectInfo } from "./project-info";
import { TagTypes } from "./tag-types";

import PacmanSolutions from "../assets/images/projects/pacman-demo.gif";
//import Bevved from "../assets/images/projects/bevved.png";
import PacmanAnalytics from "../assets/images/projects/pacman-analytics.png";


const pacman: ProjectInfo = {
  title: "Pac-Man Solutions",
  description: "An artificial intelligence environment enabling users to watch and attempt simulated solutions to the original 1980's Pac-Man arcade game.",
  image: PacmanSolutions,
  tags: [TagTypes.PYTHON, TagTypes.REACT],
  repo: "https://github.com/Davidk20/",
  demo: "https://pacman.davidkidd.dev",
};

const pacmanAnalytics: ProjectInfo = {
  title: "Pac-Man Analytics",
  description: "A Jupyter notebook analysing the performance of the different agents.",
  image: PacmanAnalytics,
  tags: [TagTypes.PYTHON, TagTypes.JUPYTER],
  repo: "https://github.com/Davidk20/pacman-analytics",
  demo: "https://davidk20.github.io/pacman-analytics/",
};

// const bevved: ProjectInfo = {
//   title: "bevved",
//   description: "A social party drinking game designed for adults, featuring a variety of prompt-based modes and designed to offer a fresh, engaging take on social drinking games.",
//   image: Bevved,
//   tags: [TagTypes.REACT],
//   repo: "",
//   demo: "https://bevved.davidkidd.dev",
// };

export default [pacman, pacmanAnalytics];
