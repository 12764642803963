/**
 * The types that a tag chip can be.
 */
export enum TagTypes {
    /**
     * Tag for a Python project.
     */
    PYTHON,
    /**
     * Tag for a Jupyter project.
     */
    JUPYTER,
    /**
     * Tag for a React project.
     */
    REACT,
    /**
     * Tag for a GitHub Link.
     */
    GITHUB,
    /**
     * Tag for a demo link.
     */
    DEMO
  }
