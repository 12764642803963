import React, { ReactElement } from "react";
import ProjectTag from "./project-tag";
import { ProjectInfo } from "../models/project-info";
import { TagTypes } from "../models/tag-types";




type ProjectCardProps = {
    project: ProjectInfo
}

export default class ProjectCard extends React.Component<ProjectCardProps>{

  override render(): React.ReactNode {
    const tags: Array<ReactElement> = [];

    for (const tag of this.props.project.tags) {
      tags.push(<ProjectTag tagType={tag}></ProjectTag>);
    }

    return (
      <div className="
        flex flex-col bg-slate-100 text-center rounded-3xl max-w-lg overflow-hidden my-4
      ">
        <img className="mb-3 shadow-lg mx-auto" src={this.props.project.image} alt="product designer"/>
        <div className="mb-4 flex flex-col gap-y-3 px-2">
          <h1 className="text-3xl font-bold text-black"> {this.props.project.title} </h1>
          <h3 className="text-sm text-black"> {this.props.project.description} </h3>
          <div className="flex gap-2 justify-center flex-wrap">
            {tags}
            {this.props.project.repo != "" &&
              <ProjectTag tagType={TagTypes.GITHUB} link={this.props.project.repo}/>
            }
            <ProjectTag tagType={TagTypes.DEMO} link={this.props.project.demo}/>
          </div>
        </div>
      </div>
    );
  }
}
