import React from "react";
import { TagTypes } from "../models/tag-types";




type ProjectTagProps = {
    tagType: TagTypes,
    link?: string
}

export default class ProjectTag extends React.Component<ProjectTagProps>{

  override render(): React.ReactNode {
    switch(this.props.tagType) {
    case TagTypes.PYTHON:
      return <img
        src="https://img.shields.io/badge/python-3670A0?style=for-the-badge&logo=python&logoColor=ffdd54"
        alt="Python Logo"
      />;
    case TagTypes.JUPYTER:
      return <a href={this.props.link}>
        <img
          src="https://img.shields.io/badge/jupyter-%23FA0F00.svg?style=for-the-badge&logo=jupyter&logoColor=white"
          alt="Jupyter Logo"
        />
      </a>;
    case TagTypes.REACT:
      return <img
        src="https://img.shields.io/badge/react-%2320232a.svg?style=for-the-badge&logo=react&logoColor=%2361DAFB"
        alt="React Logo"
      />;
    case TagTypes.GITHUB:
      return <a href={this.props.link} target="_blank" rel="noreferrer">
        <img
          src="https://img.shields.io/badge/github-%23121011.svg?style=for-the-badge&logo=github&logoColor=white"
          alt="React Logo"
        />
      </a>;
    case TagTypes.DEMO:
      return <a href={this.props.link} target="_blank" rel="noreferrer">
        <img
          src="https://img.shields.io/badge/github%20pages-121013?style=for-the-badge&logo=github&logoColor=white"
          alt="Demo Logo"
        />
      </a>;

    default:
      throw new Error("Tag doesn't match");
    }
  }
}
