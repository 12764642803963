import React from "react";
import { Route, HashRouter as Router, Routes } from "react-router-dom";
import HomeView from "./views/home-view";

export default class App extends React.Component{

  override render(): React.ReactNode {
    return (
      <Router>
        <Routes>
          <Route path="/" Component={HomeView} />
        </Routes>
      </Router>
    );
  }
}
