import React, { ReactElement } from "react";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { faGithub } from "@fortawesome/free-brands-svg-icons";
import ProjectCard from "../components/project-card";
import projects from "../models/projects";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";


export default class HomeView extends React.Component{

  override render(): React.ReactNode {

    const projectList: Array<ReactElement> = [];

    for (const project of projects) {

      projectList.push(
        <SplideSlide style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "start",
          padding: 20
        }}>
          <ProjectCard project={project}></ProjectCard>
        </SplideSlide>
      );
    }


    return (
      <div className="
        h-screen flex flex-col gap-y-10
        lg:flex-row lg:justify-center lg:items-center lg:gap-x-3 lg:mx-5
        xl:gap-x-12
      ">
        <div className="
          flex flex-col gap-y-2 h-screen/4 justify-center
          lg:w-screen/2
          xl:gap-y-4
        ">
          <span className="
            font-bold text-green-400
            text-5xl text-center
            md:text-6xl
            xl:text-7xl
          ">
            Hi, I&#39;m David
          </span>
          <span className="
            text-green-200
            text-xl text-center
            md:text-3xl
            xl:text-4xl
          ">
            A full-stack web developer from London
          </span>
          <div className="flex flex-row justify-center gap-x-3">
            <FontAwesomeIcon icon={faLinkedin} className="text-4xl md:text-6xl text-[#0087B5]"/>
            <FontAwesomeIcon icon={faGithub} className="text-4xl md:text-6xl text-white" />
          </div>
        </div>
        <div className="
          flex flex-row justify-center items-center h-screen/2 mx-6 pb-8
          lg:max-w-screen/2 lg:mx-0
        ">
          <Splide
            className="
              w-screen
              lg:w-screen/2
            "
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            aria-label="Game Modes"
            options={{
              type: "loop",
              arrows: false,
              wheel: true,
              snap: true,
              pagination: true,
              flickPower: 200,
              autoScroll: true
            }}
          >
            {projectList}
          </Splide>
        </div>
      </div>
    );
  }
}
